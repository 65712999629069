import { Loader, LoaderOptions } from 'google-maps';

export default () => {
	const componentClass = 'c-dr-service-areas';
	const loader = new Loader('AIzaSyDn5wZXDX0sK2bL1bvhLm3jhYSgvZWt3Bw', {
		version: 'weekly',
	});

	loader.load().then(function(google) {
		new google.maps.Map(document.getElementById(`${componentClass}-map`), {
			center: { lat: 35.275806, lng: -80.86772 },
			zoom: 7,
		});
	});
};
