import Swiper, { Autoplay, Pagination } from 'swiper';
import 'swiper/swiper-bundle.css';

export default () => {
	Swiper.use([Autoplay, Pagination]);

	new Swiper('.c-dr-slider-images__slider-container', {
		loop: true,
		speed: 600,
		spaceBetween: 40,
		slidesPerView: 1,
		pagination: {
			el: '.swiper-pagination',
			type: 'bullets',
			clickable: true,
		},
		breakpoints: {
			992: {
				centeredSlides: true,
				slidesPerView: 'auto',
			},
		},
	});
};
