import { Loader } from 'google-maps';

export default () => {
	const apiKey = 'AIzaSyDn5wZXDX0sK2bL1bvhLm3jhYSgvZWt3Bw';
	const loader = new Loader(apiKey, { version: 'weekly' });

	loader.load().then(function(google) {
		const map = new google.maps.Map(document.getElementById('dr-map'), {
			center: { lat: 35.275806, lng: -80.86772 },
			zoom: 15,
		});

		/** TODO: Add multiple markers dynamically. */
		new google.maps.Marker({
			position: { lat: 35.275806, lng: -80.86772 },
			map,
			title: 'Pilsen',
		});
	});
};
