import Swiper, { Autoplay, Pagination } from 'swiper';
import 'swiper/swiper-bundle.css';

export default () => {
	Swiper.use([Autoplay, Pagination]);

	new Swiper('.swiper-container', {
		loop: false,
		speed: 600,
		// autoplay: {
		//     delay: 7000,
		// },
		slidesPerView: 1,
		spaceBetween: 30,
		pagination: {
			el: '.swiper-pagination',
			type: 'bullets',
			clickable: true,
		},
		breakpoints: {
			768: {
				slidesPerView: 2,
				spaceBetween: 30,
			},
			992: {
				slidesPerView: 2,
				spaceBetween: 60,
			},
		},
	});
};
